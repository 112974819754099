import { FC } from 'react'
import styled from 'styled-components'
import { Header, Paragraph } from '@components'
import { ReactComponent as StarIcon } from 'assets/icon/star.svg'
import { RatingsApi } from 'mgw-api'

interface IRatings extends RatingsApi {}

export const Ratings: FC<IRatings> = ({ mts, imdb, kinopoisk }) => {
  return (
    <Container>
      {!!mts && (
        <div>
          <StarIcon />
          <Header as='h3' family='MTS Compact' weight={500} children={mts} />
        </div>
      )}
      {!!imdb && (
        <div>
          <Paragraph family='MTS Compact' weight={700}>IMDB</Paragraph>
          <Paragraph family='MTS Compact' weight={700}>{imdb}</Paragraph>
        </div>
      )}
      {!!kinopoisk && (
        <div>
          <Paragraph family='MTS Compact' weight={700}>КП</Paragraph>
          <Paragraph family='MTS Compact' weight={700}>{kinopoisk}</Paragraph>
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  width: min-content;

  & div {
    display: grid;
    grid-auto-flow: column;
    gap: 0.125rem;
    align-self: center;
  }
`
