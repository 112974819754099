import { FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { motion, AnimatePresence } from 'framer-motion'
import { ShelfItemApi } from 'mgw-api'
import { Poster } from '@components'
import { Spinner } from '@components/Spinner/Spinner'

interface ISearchbarList {
  query: string
  visibility: boolean
  list: ShelfItemApi[]
  loading: boolean
  onSelect: (element: ShelfItemApi) => void
}

const VARIANTS = {
  visible: {
    opacity: 1,
    height: 'auto',
    y: 0,
    x: '-50%',
  },
  hidden: {
    opacity: 0,
    height: '50%',
    y: '-1rem',
    x: '-50%',
  },
}

export const SearchbarList: FC<ISearchbarList> = observer((props) => {
  return (
    <AnimatePresence>
      <List initial={false} animate={props.visibility ? 'visible' : 'hidden'} variants={VARIANTS}>
        {props.loading ? (
          <CentredContainer>
            <Spinner/>
          </CentredContainer>
        ) : !props.list.length && (
          <SmallText>
            «{props.query}» не найден в&nbsp;каталоге, попробуйте изменить&nbsp;параметры&nbsp;поиска
          </SmallText>
        )}

        {props.list.map(el => (
          <ListElement key={el.gid} onClick={() => props.onSelect(el)}>
            <Poster src={el.imageUrl} radius='0.25' />
            <div className='titles'>
              <span>{el.title}</span>
              <span>{el.type}</span>
            </div>
          </ListElement>
        ))}
      </List>
    </AnimatePresence>
  )
})

const CentredContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
`

const List = styled(motion.div)`
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  max-height: calc(100vh / 2);
  background: var(--color-white);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.08), 0 0.25rem 1rem rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  position: relative;
  top: 1rem;
  left: 50%;
  overflow: auto;
  padding: 0.5rem 0;

  @media screen and (min-width: 1440px) {
    width: calc(100% + 56px);
    max-height: 9.375rem;
    position: absolute;
    top: calc(100% + 1rem);
  }
`

const SmallText = styled.div`
  padding: 16px;
  /* Paragraph/P3 Regular Text */
  font-family: 'MTS Text';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  /* WEB/Primary Gray */

  color: #677F99;
`

const ListElement = styled.div`
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  gap: 0.5rem;
  align-content: center;
  max-width: 100%;
  height: 4.25rem;
  padding: 0.375rem 1rem;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  & .titles {
    display: grid;
    grid-auto-flow: row;
  }
`
