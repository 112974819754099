import { makeAutoObservable } from 'mobx'
import { ShelfItemApi } from 'mgw-api'

class FavouritesStore {
  public favourites: ShelfItemApi[] = []

  constructor() {
    makeAutoObservable(this)
  }

  get positive(): string[] {
    const pos = favouritesStore.favourites.reduce((acc, el) => {
      // if (el === 'placeholder') return acc
      acc.add(el.gid)
      return acc
    }, new Set<ShelfItemApi['gid']>())

    return Array.from(pos)
  }

  public addToFavourites = (element: ShelfItemApi): void => {
    this.favourites.push(element)
  }

  public removeFromFavourites = (gid: string) => {
    const idx = this.favourites.findIndex(x => x.gid === gid)
    if (idx >= 0) this.favourites.splice(idx, 1)
  }

  public replaceFavourites = (element: ShelfItemApi, index: number): void => {
    // if (this.favourites[index] === 'placeholder') {
    //   this.addToFavourites(element)
    this.favourites[index] = element
  }
}

export const favouritesStore = new FavouritesStore()
