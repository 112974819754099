import React, { FC, useEffect } from 'react'
import { Main } from './pages'
import { PostersBackground } from '@components'
import { gtm } from 'helpers/GTMService'

export const App: FC = () => {
  useEffect(() => {
    gtm.pushEvent()
  }, [])
  
  return (
    <PostersBackground>
      <Main />
    </PostersBackground>
  )
}
