import { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { Slider, Header } from '@components'
import { CollectionElement } from './CollectionElement'
import { Details } from './Details'
import { BreakpointsEnum, IBreakpoints } from 'interfaces'
import { collectionStore, favouritesStore } from 'store'
import { MetaResponseApi, ShelfItemApiTypeEnum } from 'mgw-api'
import { autorun } from 'mobx'

const CARD_SIZES = new Map<IBreakpoints, [number, number]>([
  [BreakpointsEnum.DESKTOP, [123, 170]],
  [BreakpointsEnum.TABLET, [123, 166]],
  [BreakpointsEnum.MOBILE, [123, 145]],
])

const CARD_OFFSETS = new Map([
  [BreakpointsEnum.DESKTOP, 8],
  [BreakpointsEnum.TABLET, 8],
  [BreakpointsEnum.MOBILE, 8],
])

export const CollectionList = observer(() => {
  const sliderRef = useRef<any>()
  const [seed, setSeed] = useState<number>(1)
  const [selected, setSelected] = useState<MetaResponseApi>(collectionStore.collections[0] as MetaResponseApi)

  const onSelect = useCallback((v: MetaResponseApi) => () => setSelected(v), [setSelected])

  const onAddToFavourites = useCallback(async () => {
    collectionStore.removeFromCollections(selected.gid)
    favouritesStore.addToFavourites({
      gid: selected.gid as string,
      imageUrl: selected.verticalImageUrl as string,
      title: selected.title as string,
      type: selected.type as ShelfItemApiTypeEnum,
    })
    const count = sliderRef.current.displayElements - collectionStore.collections.length
    collectionStore.fetchMorePositiveFavourites(favouritesStore.positive, count, seed)
  }, [selected, sliderRef, seed])

  const onNext = useCallback(() => {
    // const nextSeed = seed + 1
    // setSeed(nextSeed)
    collectionStore.fetchPositiveFavourites(favouritesStore.positive, sliderRef.current.displayElements, seed)
  }, [seed])

  useEffect(
    () =>
      autorun(() => {
        if (0 in collectionStore.collections) {
          setSelected(collectionStore.collections[0])
        }
      }),
    []
  )

  return (
    <Container>
      <CollectionHeading 
        as='h2' 
        family='MTS Wide' 
        weight={500} 
        children='Ваша подборка' 
      />
      
      <Slider
        seed={seed}
        sliderRef={sliderRef}
        CARD_SIZES={CARD_SIZES}
        CARD_OFFSETS={CARD_OFFSETS}
        elements={collectionStore.collections}
        Poster={CollectionElement}
        selected={selected}
        onSelect={onSelect}
        onNext={onNext}
      />
      <AnimatePresence>
        {!!selected && (
          <Details onAddToFavourites={onAddToFavourites} selected={selected} />
        )}
      </AnimatePresence>
    </Container>
  )
})

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 3rem;
  max-width: 100%;
  overflow: hidden;
`

const CollectionHeading = styled(Header)`
  text-align: center;

  @media screen and (max-width: 768px) {
    padding: 32px 16px 0;
  }
`
