// TODO refactoring
var timerId: ReturnType<typeof setTimeout> | null = null;

export const debounce = (callback: () => any, timeout: number) => {
    return (...args: any) => {
        if (timerId) clearTimeout(timerId)
        timerId = setTimeout(() => {
            callback.apply(this, args)
        }, timeout)
    }
}