import { FC, useCallback } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Header, Ratings, Button, Paragraph } from '@components'
import { ContentsApi, MetaResponseApi, MetaResponseApiTypeEnum, SeasonApi, EpisodeApi } from 'mgw-api'
import { gtm } from 'helpers/GTMService'

const contentsApi = new ContentsApi(undefined, 'https://mgw.mts.ru')

interface IDetails {
  onAddToFavourites: () => void
  selected: MetaResponseApi
}

export const Details: FC<IDetails> = observer(({ onAddToFavourites, selected }) => {
  const onOpen = useCallback(async () => {
    gtm.pushEvent({
      event: 'mayak_button_click', 
      event_name: 'mayak_button_click', 
      button_id: '3',
      button_text: 'Смотреть в KION',
    })

    const url = ['//kion.ru', 'video']
    if (!selected.hid) return
    if (selected.type === MetaResponseApiTypeEnum.Series) {
      const {
        data: { seasons },
      } = await contentsApi.getSeasonsBySeriesGid(selected.hid, '0', 'PC_FairPlay_v1', '0', '0')
      const [{ hid: seasonContentId, episodes }] = seasons as SeasonApi[]
      const [{ hid: episodeContentId }] = episodes as EpisodeApi[]

      url.push(...['serial', selected.hid, 'season', seasonContentId || '', 'episode', episodeContentId || ''])
    } else {
      url.push(...['movie', selected.hid])
    }
    window.open(url.join('/'))
  }, [])

  function onAdd() {
    gtm.pushEvent({
      event: 'mayak_button_click', 
      event_name: 'mayak_button_click', 
      button_id: '4',
      button_text: 'Добавить в подборку',
    })
    
    onAddToFavourites()
  }
  return (
    <Container>
      <Header as='h2' family='MTS Wide' weight={500}>
        {selected.title}
      </Header>
      <Ratings mts={selected.ratings?.mts} imdb={selected.ratings?.imdb} kinopoisk={selected.ratings?.kinopoisk} />
      <Controls>
        <Button className='light' onClick={onOpen}>
          Смотреть в KION
        </Button>
        <Button className='secondary' onClick={onAdd}>
          Добавить в подборку
        </Button>
      </Controls>
      <Description>
        <Header as='h2'>Описание</Header>
        <Paragraph>{selected.description}</Paragraph>
      </Description>
    </Container>
  )
})

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;

  @media screen and (max-width: 768px) {
    padding: 0 16px 32px 16px;
  }
`

const Controls = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1.5rem;
  width: min-content;

  @media screen and (max-width: 768px) {
    gap: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

const Description = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1.125rem;
`
