import { useLocalObservable } from 'mobx-react-lite'
import { SearchApi, ShelfItemApi } from 'mgw-api'
import { axiosInstance } from 'api'
import { debounce } from 'helpers'
import { gtm } from 'helpers/GTMService'

const searchApi = new SearchApi(undefined, axiosInstance.defaults.baseURL, axiosInstance as any)

interface ISearchStore {
  result: ShelfItemApi[]
  loading: boolean
  fetchSearch: (term: string) => Promise<void>
}

export const useSearchStore = () =>
  useLocalObservable<ISearchStore>(() => ({
    result: [],
    loading: false,

    async fetchSearch(term) {
      this.loading = true

      const searchDebounced = debounce(async () => {
        gtm.pushEvent({
          event: 'mayak_search', 
          event_name: 'mayak_search', 
          term: term,
        })
        
        const res = await searchApi.getSearchResult(
          term,
          30,
          '0',
          '1',
          'PC_FairPlay_v1',
          '1',
          '1',
          'SEARCH_VOD',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        )

        this.result = res.data.items?.[0]?.items || []
        this.loading = false

        console.log('search result:', this.result);
      }, 350)

      searchDebounced()
    },
  }))
