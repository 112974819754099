import { FC } from 'react'
import { Caption } from '@components'
import s from './footer.module.scss'

export const Footer: FC = () => {
  const currentYear = new Date().getFullYear()

  return (
    <div className='container'>
      <footer className={s['container']}>
        <Caption 
          family='MTS Compact'
          weight={500}
          children={`© ${currentYear} ПАО «МТС». Все права защищены.`} 
        />
      </footer>
    </div>
  )
}
