import styled, { css } from 'styled-components'

interface IFontProps {
  family?: 'MTS Wide' | 'MTS Compact' | 'MTS Text'
  weight?: number
}

export const Header = styled.h1<IFontProps>`
  color: #fff;
  font-family: ${p => `${p.family}, sans-serif`};
`

Header.defaultProps = {
  family: 'MTS Wide',
}

interface IParagraph extends IFontProps {
  size?: 's' | 'm' | 'l'
}

const ParagraphSizes: Record<'s' | 'm' | 'l', any> = {
  l: css`
    font-size: 1.25rem;
  `,
  m: css`
    font-size: 1.0625rem;
  `,
  s: css`
    font-size: 0.875rem;
  `,
}

export const Paragraph = styled.p<IParagraph>`
  color: #fff;
  font-family: ${p => `${p.family}, sans-serif`};
  ${p => ParagraphSizes[p.size as 's' | 'm' | 'l']};

  @include mobile {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`

Paragraph.defaultProps = {
  family: 'MTS Compact',
  size: 'm',
}

export const Caption = styled.span<IFontProps>`
  color: #fff;
  font-family: ${p => `${p.family}, sans-serif`};
  /* font-size: 0.75rem; */
  /* line-height: 1.25rem; */
`
