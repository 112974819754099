import { FC } from 'react'
import { Header, Paragraph } from '@components'
import { FavouriteList } from './components'
import { ReactComponent as Logo } from 'assets/logo.svg'
import s from './Header-section.module.scss'

export const HeaderSection: FC = () => {
  return (
    <div className='container'>
      <div className='row'>
        <section className={s['container']}>
          <Logo className={s['logo']} />
          <div className={s['wrapper']}>
            <div className={s['inner']}>
              <Header
                as='h1'
                family='MTS Wide'
                children={
                  <>
                    Онлайн-кинотеатр, который
                    <br /> вы полюбите
                  </>
                }
              />
              <Paragraph
                family='MTS Text'
                children='Выберите фильмы или сериалы, которые вам понравились, а мы подберем для вас похожие'
              />
            </div>
            <div className={s['posters']}>
              <FavouriteList />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
