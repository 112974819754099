import { FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import { collectionStore } from 'store'
import { CollectionList } from './components'
import { WRAPPER_TRANSITIONS, CONTAINER_TRANSITIONS } from './variants.config'
import { BreakPoints } from 'styles/global-styles'

export const CollectionSection: FC = observer(() => {
  const condition = collectionStore.collections.length > 1 ? 'visible' : 'hidden'

  return (
    <div className='container'>
      <div className='row'>
        <Container>
          <AnimatedWrapper initial={'hidden'} animate={condition} variants={WRAPPER_TRANSITIONS} transition={{ duration: 1 }}>
            <AnimatedContainer animate={condition} variants={CONTAINER_TRANSITIONS}>
              {condition === 'visible' && <CollectionList />}
            </AnimatedContainer>
          </AnimatedWrapper>
        </Container>
      </div>
    </div>
  )
})

const Container = styled.div`
  display: grid;
  margin: 80px auto 0;

  @media screen and (max-width: ${BreakPoints.tablet}) {
    margin-left: unset !important;
    margin-right: unset !important;
  }
`

const AnimatedWrapper = styled(motion.div)`
  width: 100%;
`

const AnimatedContainer = styled(motion.div)`
  background: var(--color-black-100);
  border-radius: 50px;
  padding: 68px 56px;

  @media screen and (max-width: ${BreakPoints.desktop}) {
    padding: 36px;
  }

  @media screen and (max-width: ${BreakPoints.tablet}) {
    padding: 0;
    border-radius: 0;
  }
`
