import { makeAutoObservable, runInAction } from 'mobx'
import { v4 } from 'uuid'
import { ShelfItemApi, ContentsApi, MetaResponseApi } from 'mgw-api'
import * as flashlightApi from 'flashlight-api'

class CollectionStore {
  // @ts-ignore
  flashlightApi = new flashlightApi.ApiApi({
    basePath: 'https://mayak.superanalytics.ru',
  })
  contentsApi = new ContentsApi(undefined, 'https://mgw.mts.ru')
  uuid: string
  collections: MetaResponseApi[]
  negativeCollections: string[]

  constructor() {
    makeAutoObservable(this)
    this.uuid = v4()
    this.collections = []
    this.negativeCollections = []
  }

  public get collectionsGid(): Set<string> {
    return this.collections.reduce<Set<string>>((acc, { gid }) => {
      if (!!gid) acc.add(gid)
      return acc
    }, new Set())
  }

  public refreshCollections = () => {
    this.collections = []
  }

  public addToNegative = () => {
    const ids = Array.from(this.collectionsGid.values())
    this.negativeCollections.push(...ids)
  }

  private addToPositive = (elements: (MetaResponseApi | null)[]) => {
    elements.forEach(value => {
      if (!!value?.gid && !this.collectionsGid.has(value.gid)) {
        this.collections.push(value)
      }
    })
  }

  public removeFromCollections = (gid: MetaResponseApi['gid']): void => {
    const index = this.collections.findIndex(el => el.gid === gid)
    this.collections.splice(index, 1)
  }

  public getPageApiPagePut = async (pos_glos: ShelfItemApi['gid'][], count: number, seed: number) => {
    try {
      const {
        data: { items },
      } = await this.flashlightApi.getPageApiPagePut(
        count,
        this.uuid,
        {
          pos_glos,
          neg_glos: this.negativeCollections,
        },
        seed
      )

      const contents: MetaResponseApi[] = items.map((gid: ShelfItemApi['gid']) =>
        this.contentsApi
          .getMetaByGid(gid, '0', 'PC_FairPlay_v1')
          .then(({ data }) => data)
          .catch(() => null)
      )
      return await Promise.all(contents)
    } catch (e) {
      console.warn(e)
      return []
    }
  }

  public fetchPositiveFavourites = async (pos_glos: ShelfItemApi['gid'][], count: number, seed: number) => {
    const response = await this.getPageApiPagePut(pos_glos, count, seed)
    runInAction(() => {
      collectionStore.addToNegative()
      collectionStore.refreshCollections()
      this.addToPositive(response)
    })
  }

  public fetchMorePositiveFavourites = async (pos_glos: ShelfItemApi['gid'][], count: number, seed: number) => {
    const response = await this.getPageApiPagePut(pos_glos, count, seed)
    runInAction(() => {
      this.addToPositive(response)
    })
  }
}

export const collectionStore = new CollectionStore()
