import { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import layout from 'assets/layout.png'

export const PostersBackground: FC<PropsWithChildren> = observer(({ children }) => <Container>{children}</Container>)

const Container = styled.div`
  background-image: url(${layout});
  background-size: cover;
  height: 100%;
  // overflow: hidden scroll;
`
