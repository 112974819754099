import { useCallback, useRef, useState, useImperativeHandle, FC, useMemo } from 'react'
import { computed } from 'mobx'
import { AnimatePresence, motion, Transition } from 'framer-motion'
import styled from 'styled-components'
import { useResizeObserver, useResponsiveBreakpoints } from 'helpers/hooks'
import { Control } from './Control'
import { ReactComponent as ArrowRightIcon } from '../../assets/icon/arrow-right.svg'
import { IBreakpoints } from '../../interfaces'
import { BreakPoints } from 'styles/global-styles'

const TRANSITION: Transition = {
  when: 'afterChildren',
  ease: 'easeOut',
}

interface ISlider {
  seed: number
  sliderRef: any
  CARD_SIZES: Map<IBreakpoints, [number, number]>
  CARD_OFFSETS: any
  elements: any[]
  Poster: FC<any>
  selected?: any
  onSelect?: (v: any) => () => void
  onNext: () => void
}

export const Slider: FC<ISlider> = ({
  seed,
  CARD_SIZES,
  CARD_OFFSETS,
  elements,
  selected = 0,
  onSelect = () => () => {},
  Poster,
  sliderRef,
  onNext,
}) => {
  const container = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState(0)
  const { size } = useResizeObserver(container)
  const { breakpoint } = useResponsiveBreakpoints()

  const [cardWidth, cardHeight] = CARD_SIZES.get(breakpoint) as [number, number]
  const cardOffset = CARD_OFFSETS.get(breakpoint) as number

  const count = elements.length
  const displayElements = Math.floor((size?.width || 1350) / (cardWidth + cardOffset))

  const list = computed(() => {
    return Array.from({ length: count }, (item, idx) => elements[(idx + position + count) % count])
  }).get()

  const onPrev = useCallback(() => {}, [])

  const isNextDisabled = useMemo(() => {
    return seed < 1
  }, [])

  useImperativeHandle(sliderRef, () => ({
    get displayElements(): number {
      return displayElements
    },
  }))

  return (
    <Container ref={container} className="slider-container">
      <PosterList
        className='scrollable-x'
        transition={TRANSITION}
      >
        <AnimatePresence>
          {list.map((v, idx) => (
            <Poster
              className="slide-poster clickable"
              key={idx + position}
              idx={idx}
              item={v}
              offset={cardOffset}
              selected={selected}
              onClick={onSelect(v)}
            />
          ))}
        </AnimatePresence>
      </PosterList>

      <Control 
        condition={isNextDisabled ? 'hidden' : 'visible'} 
        icon={<ArrowRightIcon />}
        onClick={onNext}
        disabled={isNextDisabled}
      />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 2rem;
  grid-auto-columns: max-content;
  justify-content: flex-end;
  grid-gap: 1.5rem;
  max-width: inherit;

  @media screen and (max-width: ${BreakPoints.desktop}) {
    grid-template-columns: 1fr 0px !important;
    gap: 0;
  }
`

const PosterList = styled(motion.div)`
  max-width: 100%;
  height: 168px;
  position: relative; 
  
  display: flex;
  gap: 16px;

  @media screen and (max-width: ${BreakPoints.desktop}) {
    height: 168px;
    gap: 11px;
  }

  @media screen and (max-width: ${BreakPoints.tablet}) {
    height: 140px;
    gap: 8px;
  }

  .slide-poster {
    @media screen and (max-width: ${BreakPoints.tablet}) {
      &:first-of-type {
        margin-left: 16px;
      }

      &:last-of-type {
        margin-right: 16px;
      }
    }
  }
`
