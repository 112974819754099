import { FC, MouseEvent } from 'react'
import { Poster } from '@components'
import { MetaResponseApi } from 'mgw-api'
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface ICollectionElement {
  className: string
  selected: MetaResponseApi
  width: number
  offset: number
  idx: number
  item: MetaResponseApi | 'placeholder'
  onClick: (ev: MouseEvent) => void
  isActive: boolean
}

export const CollectionElement: FC<ICollectionElement> = (props) => {
  const src = props.item === 'placeholder' ? 'placeholder' : props.item?.verticalImageUrl

  return (
    <Container
      className={props.className}
      initial={{ opacity: 0, scale: 0.7 }}
      animate={{ opacity: 1, scale: 1, transition: { delay: 0.1, ease: 'easeOut' } }}
      exit={{ opacity: 0, scale: 0.7, }}
      style={{ width: props.width }}
      onClick={props.onClick}
    >
      <Poster src={src} radius='1' />
      {props.item !== 'placeholder' && props.item?.gid === props.selected?.gid && (
        <Underline layoutId='underline' />
      )}
    </Container>
  )
}

const Container = styled(motion.div)`
  position: relative;
  height: 100%;
  aspect-ratio: 0.72;
`

const Underline = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 1rem;
  border: 2px solid white;
  z-index: 1;
`
