import { FC, useCallback, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Button, Poster, SearchBar } from '@components'
import { favouritesStore, collectionStore } from 'store'
import { ReactComponent as CrossIcon } from 'assets/icon/cross.svg'
import { ShelfItemApi } from 'mgw-api'
import { observer } from 'mobx-react-lite'
import { gtm } from 'helpers/GTMService'

interface IFavouriteElement {
  width: number
  offset: number
  imageUrl?: string
  idx: number
  id: string
  className?: string
  onAdd?: () => void
  onRemove?: () => void
}

export const FavouriteElement: FC<IFavouriteElement> = observer((props) => {
  const [isEdit, setEdit] = useState(false)
  const isPlaceholder = props.imageUrl === 'placeholder'
  const x = props.idx * (props.width + Math.min(props.offset * props.idx, props.offset))

  function onClick(element: ShelfItemApi) {
    if (props.imageUrl === 'placeholder') {
      favouritesStore.addToFavourites(element)
      props.onAdd?.()
    } else {
      favouritesStore.replaceFavourites(element, props.idx)
    }
    collectionStore.fetchPositiveFavourites(favouritesStore.positive, 8, 1)
    setEdit(false)      
  }
  
  const onEdit = useCallback((v: boolean) => () => setEdit(v), [setEdit])

  function onRemove() {
    favouritesStore.removeFromFavourites(props.id)
    props.onRemove?.()
  }

  function onClickButton(button: {id: string, text: string}) {
    onEdit(true)()

    gtm.pushEvent({
      event: 'mayak_button_click', 
      event_name: 'mayak_button_click', 
      button_id: button.id,
      button_text: button.text,
    })
  }

  return (
    <Container
      className={props.className}
      initial={{ opacity: 0, scale: 0.7, left: x }}
      animate={{ opacity: 1, scale: 1, left: x, transition: { delay: 0.1, ease: 'easeOut' } }}
      exit={{ opacity: 0, scale: 0.7, left: x }}
      style={{ width: props.width }}
    >
      {!isPlaceholder && (
        <RemoveControl onClick={onRemove}>
          <CrossIcon />
        </RemoveControl>
      )}
      <Poster src={props.imageUrl} radius='1' style={{ width: props.width }} />
      {isEdit ? (
        <SearchBar onSelect={onClick} onBlur={onEdit(false)}/>
      ) : (
        <AttachButton 
          className={isPlaceholder ? 'primary' : 'secondary'} 
          onClick={() => onClickButton({
            id: isPlaceholder ? '1' : '2', 
            text: isPlaceholder ? 'Добавить' : 'Выбрать другой'}
          )}
        >
          {isPlaceholder ? 'Добавить' : 'Выбрать другой'}
        </AttachButton>
      )}
    </Container>
  )
})

const RemoveControl = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0;
  transform: scale(0.9);
  transition: all 300ms ease-in;
  z-index: 1;
`

const Container = styled(motion.div)`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  position: absolute;

  &:not(:first-of-type):before {
    position: absolute;
    top: calc(50% - (3.25rem / 2));
    left: -1.375rem;
    font-size: 1.5rem;
    color: var(--color-white);

    @media screen and (min-width: 1440px) {
      content: '+';
    }
  }

  &:hover ${RemoveControl} {
    opacity: 1;
    transform: scale(1);
  }
`

const AttachButton = styled(Button)`
  min-width: 100%;
`
