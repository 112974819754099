import { FC } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const VARIANTS = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

interface IControl {
  condition: 'visible' | 'hidden'
  icon: JSX.Element
  onClick: () => void
  disabled: boolean
}

export const Control: FC<IControl> = ({ condition, icon, onClick, disabled }) => (
  <Container animate={condition} variants={VARIANTS}>
    <AnimatedButton whileTap={{ scale: 0.9 }} onClick={onClick} disabled={disabled}>
      {icon}
    </AnimatedButton>
  </Container>
)

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
`

const AnimatedButton = styled(motion.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: all 200ms linear;
  border: 1px solid transparent;
  transform: scale(0.9);

  &:disabled {
    opacity: 0;
    transform: scale(0.9);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.05);
    transform: scale(1.1);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`
