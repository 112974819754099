import styled from 'styled-components'

interface IPoster {
  src?: string
  radius: '0.25' | '0.5' | '1'
}

export const Poster = styled.div<IPoster>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  aspect-ratio: 193 / 268;
  border-radius: ${p => `${p.radius}rem`};
  position: relative;
  background-size: cover;

  &:before {
    content: '';
    background: var(--color-skeleton);
    opacity: 0.7;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
  }

  &:after {
    font-weight: 700;
    font-size: 6.25rem;
    line-height: 1.5rem;
    color: var(--color-white);
    user-select: none;
    font-family: CompactBold;
  }

  ${p =>
    p.src === 'placeholder'
      ? `
    &:after {
      content: '?';
    }
  `
      : `
    background-image: url(${p.src});
  `}
`
