import { useState, useRef, useEffect, ChangeEvent, FC, useCallback } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useSearchStore } from 'store'
import { SearchbarList } from './components'
import { ShelfItemApi } from 'mgw-api'
import { Button } from '@components/Button'
import { ReactComponent as DeleteIcon } from 'assets/icon/delete.svg'

interface ISearchBar {
  onSelect: (element: ShelfItemApi) => void
  onBlur: () => void
}

export const SearchBar: FC<ISearchBar> = observer((props) => {
  const searchStore = useSearchStore()
  const container = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useState<string>('')

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value)
  }

  useEffect(() => {
    if (query) searchStore.fetchSearch(query)
  }, [query])

  function blur() {
    if (query) return
    props.onBlur()
  }

  function clear() {
    setQuery('')
    container.current?.focus()
  }

  function escapePressed(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== 'Escape') return
    blur()
  }

  return (
    <Container>
      <InputWrapper>
        <InputField 
          ref={container} 
          value={query} 
          onChange={onChange}
          autoFocus 
          onBlur={blur}
          onKeyDown={escapePressed}
        />
        <Button 
          className='text' 
          onClick={clear} 
          style={{
            position: 'absolute', 
            top: 0,
            right: 0,
            paddingRight: '0.8rem',
            color: 'black',
            zIndex: 1
          }}
        >
          <DeleteIcon />
        </Button>
      </InputWrapper>

      <SearchbarList 
        query={query}
        visibility={!!query}
        list={searchStore.result} 
        onSelect={props.onSelect}
        loading={searchStore.loading}
      />
    </Container>
  )
})

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  z-index: 1;

  &:before {
    content: '';
    background: var(--color-black-100);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media screen and (min-width: 1440px) {
    position: relative;
    padding: 0;
  }
`

const InputWrapper = styled.div`
  position: relative;
`

const InputField = styled.input`
  padding: 14px 40px 14px 12px;
  width: 100%;
  max-width: 100%;
  height: 3.25rem;
  background: var(--color-white-100);
  border-radius: 0.5rem;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;

  font-size: 1rem;

  &:focus {
    border: 1px solid var(--color-blue);
    color: var(--color-black);
  }
`
