import styled, { keyframes } from "styled-components";

const flashAnimation = keyframes`
    0% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF2, -32px 0 var(--color-black-100);
    }
    50% {
        background-color: var(--color-black-100);
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
    }
    100% {
        background-color: #FFF2;
        box-shadow: 32px 0 var(--color-black-100), -32px 0 #FFF2;
    }
`

export const Spinner = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--color-black-100);
    box-shadow: 32px 0 var(--color-black-100), -32px 0 var(--color-black-100);
    animation: ${flashAnimation} 0.5s ease-out infinite alternate;
`