import { createGlobalStyle } from 'styled-components'
import './global.scss'
import './colors.scss'
import './fonts.scss'
import './grid.scss'
import './mixins.scss'
import './variables.scss'

export const BreakPoints = {
  tablet: '767.5px',
  desktop: '1339.5px',
}

export const GlobalStyle = createGlobalStyle`  
  * {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  html {
    font-family: 'MTS Sans', 'Arial', sans-serif;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #001424E6;
  }
  
  input {
    border: none;
    outline: none!important;
    box-sizing: border-box;
  }
  
  button {
    background: transparent;
    padding: 0;
    border: 0;
  }

  body {
    width: 100%;
    height: 100vh;
    position: relative;
    // overflow: hidden scroll;
    background-image: linear-gradient(180deg, rgba(0, 26, 47, 0) 61.66%, #001A2F 100%);

    &:before {
      content: '';
      background:
              radial-gradient(64.79% 64.79% at 19.27% -14.79%, #073A7C 0%,rgba(6, 33, 63, 0.5) 100%),
              radial-gradient(88.02% 88.02% at 91.11% 97.66%, #160A52 0%, rgba(6, 33, 63, 0.5) 100%), #09102D;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      opacity: 0.7;
    }

    & > div {
      height: 100%;
    }
  }

  h1 {
    font-weight: 500;
    font-size: 44px;
    line-height: 44px;

    @include mobile {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
    }
  }

  h2 {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
`
