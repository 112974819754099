export const CONTAINER_TRANSITIONS = {
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    scale: 1.1,
    y: '3rem',
  },
}

export const WRAPPER_TRANSITIONS = {
  visible: {
    height: '100%',
    overflow: 'hidden',
  },
  hidden: {
    height: 0,
    overflow: 'hidden',
  },
}
